/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

[data-v-app] {
  width: 100%;
}
.darklight-reference-promo {
  display: none !important;
}
.darklight {
  margin-bottom: 20px;
}
/* tweak additional scalar docs css here - https://scalar.com/swagger-editor */
.dark-mode {
  --scalar-background-1: #293742;
  --scalar-background-2: #394b59;
  --scalar-background-3: #1585c5;

  --scalar-background-accent: #1585c5;
}
/* tnw - the header is unused in our case and was overlapping and preventing clicking the search box */
.references-header {
  display: none;
}
.light-mode input {
  color: black;
}
.light-mode {
  color: black;
}
